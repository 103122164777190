import { useLayoutEffect } from 'react';

export const RedirectPage = () => {
  useLayoutEffect(() => {
    setTimeout(() => {
      document.location.href = 'https://launchpad.spores.app';
    }, 333);
  }, []);
    
  return <>Loading, please wait...</>;
};
