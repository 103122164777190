import { Redirect, Route, Switch } from 'react-router';
import { privateRoute } from 'routes';
import { RitestreamLanding } from 'views/Ritestream';
import { WizardiaLanding } from 'views/Wizardia';
import { RedirectPage } from 'views/RedirectPage';
import { SupernovaLanding } from 'views/Supernova';
import { RoeLanding } from 'views/Roe';
import { OokeengaLanding } from 'views/Ookeenga';
import { AnimalConcertsLanding } from 'views/AnimalConcerts';

const Landing = () => {
  return (
    <>
      <Switch>
        <Route path={privateRoute.projectLanding.urlRitestream} component={RitestreamLanding} />
        <Route path={privateRoute.projectLanding.urlWizardia} component={WizardiaLanding} />
        <Route path={privateRoute.projectLanding.urlSupernova} component={SupernovaLanding} />
        <Route path={privateRoute.projectLanding.urlRoe} component={RoeLanding} />
        {/* <Route path={privateRoute.projectLanding.urlOokeenga} component={OokeengaLanding}t/> */}
        {/* <Route path={privateRoute.projectLanding.urlAnimalConcerts} component={AnimalConcertsLanding} /> */}
        <Redirect to='/' componen={RedirectPage} />
      </Switch>
    </>
  );
};

export default Landing;
