import { Grid, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HowToBuy = () => {
  return (
    <div style={{ color: '#B6B6BE', fontSize: '18px' }}>
      <div className='font-bold text-4xl mb-6' style={{ color: '#FAFAFA' }}>
        I. KYC & Registration
      </div>
      <div className='mb-4'>
        In order to be eligible for joining IDOs on Spores Launchpad, you need to complete KYC process. For those who
        have not completed KYC previously: please click <span> </span>
        <MuiLink
          className='hover:underline text-yellow-300'
          href='https://in.sumsub.com/idensic/l/#/uni_dgsdMlCWmLETygri'
        >
          HERE
        </MuiLink>
      </div>
      <div className='mb-4'>
        The KYC process requires email verification, identity document and face match. Please prepare related documents
        (ID card, passport or driving license) and follow the instructions. You will receive an email confirming your
        KYC status.
      </div>
      <div className='mb-4'>
        Then, you will need to register to get whitelisted for the IDO. Click the ‘Get Whitelisted Now’ button and fill
        all the required information. Please note that you have to stake $SPO token to be eligible for VIP whitelist.
        Staking pools infomation to be announced on Spores social media.
      </div>
      <div className='flex mb-8 justify-center'>
        <img className='' src={require('assets/projects/supernova/howto1.png').default} />
      </div>

      <div className='font-bold text-4xl mb-6' style={{ color: '#FAFAFA' }}>
        II. How to buy IDO on Spores Launchpad
      </div>
      <div className='font-bold mb-4' style={{ color: '#FAFAFA' }}>
        Logging in
      </div>
      <ul className='list-disc list-inside'>
        <li className='mb-4'>
          Please use your MetaMask wallet (on BNB Chain) to log in and purchase on Spores Launchpad. If your MetaMask
          wallet has not been connected to BNB Chain, please follow the instructions <span> </span>
          <MuiLink
            className='hover:underline text-yellow-300'
            href='https://metamask.zendesk.com/hc/en-us/articles/360043227612-How-to-add-a-custom-network-RPC'
          >
            HERE.
          </MuiLink>
        </li>
        <li className='mb-4'>
          <span className='font-bold text-yellow-300'>DO NOT </span>use your cold wallet or multi-signature wallet as
          you may encounter some unaware troubles.
        </li>
        <li className='mb-4'>
          Please ensure that you have sufficient balance in your wallet before taking part in the sales so you will not
          miss any desired item.
        </li>
      </ul>
      <div className='font-bold mb-4' style={{ color: '#FAFAFA' }}>
        2. Buying
      </div>
      <div className='mb-4'>
        Upon logging in, the system will determine your VIP Level and show your maximum quota (your allocation) that
        you’re able to purchase.
      </div>
      <div className='flex py-4 mb-4'>
        <p>
          <img
            className='mr-8 mb-4 float-left object-none'
            src={require('assets/projects/supernova/howto2.png').default}
          />

          <div>Please see the example below:</div>
          <ul className='list-disc list-inside'>
            <li>You’re listed as VIP Level 5.</li>
            <li>Your Allocation is: $5,000.</li>
          </ul>
          <div className='mb-4'>To start buying, first you need to select the currency (USDT or BUSD).</div>
          <div className=''>
            Next, input the amount you want to purchase. Please note that you can only input the number within your
            allocation. You can click ‘Max’ to input the maximum amount allocated.
          </div>
          <div className='mb-4'>
            Upon clicking ‘CHECK OUT’, your MetaMask wallet shall automatically open and you will have 10 minutes to
            proceed with your payment to the provided wallet address and submit the order.
          </div>
        </p>
      </div>
      <div className='mb-4'>
        Please make sure that your payment is correct and successfully transferred, your Transaction ID in the pop-up is
        automatically filled, then click ‘SUBMIT’.
      </div>
      <div className='mb-6'>
        Upon confirming your transaction on MetaMask, the system will automatically fill your Transaction ID into the
        Checkout pop-up. Please be patient since the process may take a few minutes to complete. You can also manually
        input the Transaction ID.
      </div>
      <div className='flex mb-8 justify-center'>
        <img className='' src={require('assets/projects/supernova/howto3.png').default} />
      </div>
      <div className='mb-5'>
        Your order will be recorded and delivered to our Operations Team to confirm. Should there be any complications
        during the process, just drop us a note at:
        <span className='font-bold text-yellow-300'> customersupport@spores.app </span>
      </div>
      <div className='font-bold mb-4' style={{ color: '#FAFAFA' }}>
        3. View Order History
      </div>
      <div className='mb-4'>
        The Order History page lists all your orders and their status. Click on ‘Order History’ on the header menu to go
        to the page. If there is any incorrect information, please contact Spores Customer Support to verify.
      </div>
      <div className='flex justify-center'>
        <img className='mb-8' src={require('assets/projects/supernova/howto4.png').default} />
      </div>

      <div className='font-bold text-4xl mb-6' style={{ color: '#FAFAFA' }}>
        III. How to claim your token
      </div>
      <div className='flex py-4 mb-4'>
        <p>
          <img
            className='mr-8 mb-4 float-left object-none'
            src={require('assets/projects/supernova/howto5.png').default}
          />

          <div className='mb-4'>
            When the project starts releasing its token, you can claim your purchased amount on Spores Launchpad as per
            vesting schedule.
          </div>
          <div className='mb-4'>
            Upon logging in with your wallet, the system will determine your purchased amount and total amount
            claimable.
          </div>
          <div className='mb-4'>
            Click the ‘CLAIM’ button, your MetaMask wallet shall automatically open. Please confirm the transaction to
            receive the claimable amount.
          </div>
        </p>
      </div>

      <div className='font-bold text-4xl mb-6' style={{ color: '#FAFAFA' }}>
        IV. FAQs
      </div>
      <div className='font-bold mb-4' style={{ color: '#FAFAFA' }}>
        1. Payment issues
      </div>
      <div className='mb-4'>
        1.1. What to do when I underpay the order?<br></br> When you accidentally underpay your order, you can make
        another transaction for the unpaid amount and inform us via customersupport@spores.app to secure your items. In
        case you do not take any further action, the order will get rejected and we will refund you in compliance with
        the refund policy.
      </div>
      <div className='mb-4'>
        1.2. What to do when I overpay the order?<br></br> If you overpay for your order, we’ll still accept the order
        and the excessive amount will be transferred back to your wallet in compliance with the refund policy.
      </div>
      <div className='mb-4'>
        1.3. What to do when I pay for the order in the wrong crypto currencies?<br></br> For each sale campaign, we’ll
        accept the payment in one or some specific crypto currencies. If you transfer to us in the wrong crypto
        currency, your order will be rejected and we’ll refund you in compliance with our policy. In order to get your
        favorite items, please make another order and pay for it in the right currency.
      </div>
      <div className='font-bold mb-4' style={{ color: '#FAFAFA' }}>
        2. Order confirmation & post-sale information
      </div>
      <div className='mb-4'>
        2.1. How to check if my order has been confirmed or not? <br></br>
        You can visit the Order History page directly on Spores Launchpad to check your order status after 30 minutes to
        1 hour. If every order detail is correct, your order will be confirmed and shown as APPROVED. If there is any
        incorrect information, it will be rejected and displayed accordingly on the Order History page. Spores Team will
        also send you an order confirmation email after the sale ends.
      </div>
      <div className='mb-4'>
        2.2. Where can I get post-sale information?<br></br>
        Any post-sale information will be published on Spores and partners’ website, medium, twitter & social media.
        Please refer to them or contact us via customersupport@spores.app.
      </div>
    </div>
  );
};

export default HowToBuy;
