import { store } from 'reducers';
import { accountService } from 'services/account';
import { marketService } from 'services/market';
import { signIn } from 'reducers/profileSlice';
import { openAlert } from 'reducers/alertSlice';
import Web3 from 'web3';

export const connectWalletOnly = async (callback) => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    return store.dispatch(openAlert({ message: 'Please install MetaMask', variant: 'warning' }));
  }

  const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });

  const { isLoggedIn } = store.getState().profile;
  if (isLoggedIn) {
    if (typeof callback === 'function') callback();
  } else {
    try {
      store.dispatch(signIn({ address: user }));
      if (typeof callback === 'function') callback();
    } catch {}
  }
}

export const connectWallet = async (callback) => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    return store.dispatch(openAlert({ message: 'Please install MetaMask', variant: 'warning' }));
  }

  const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });

  const { isLoggedIn } = store.getState().profile;
  if (isLoggedIn) {
    if (typeof callback === 'function') callback();
  } else {
    try {
      const nonce = await accountService.connect({ public_address: user });
      const message = `login one-time code: ${nonce}`;
      const rawSig = await window.web3.eth.personal.sign(message, user);
      const splitAt = rawSig.length - 2;
      let v = rawSig.slice(-2);
      if (v === '00') {
        v = '1b';
      } else if (v === '01') {
        v = '1c';
      }
      const signature = rawSig.substring(0, splitAt) + v;

      const { ...token } = await accountService.login({ public_address: user, signature });
      store.dispatch(signIn({ address: user, ...token }));
      const { name, product_can_buy, discount, min_discount_quantity } = await marketService.getQuota();
      store.dispatch(signIn({ tier: name, isVip: !!name, products: product_can_buy, discount, min_discount_quantity }));

      if (typeof callback === 'function') callback();
    } catch {}
  }
};
