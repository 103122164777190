import { RedirectPage } from 'views/RedirectPage';
import { Landing } from 'views/Landing';

const privateRoute = {
  home: {
    path: '/',
    component: RedirectPage,
  },
  projectLanding: {
    path: '/:project',
    urlRitestream: '/ritestream',
    urlWizardia: '/wizardia',
    urlSupernova: '/supernova',
    urlRoe: '/realmsofeth',
    // urlOokeenga: '/ookeenga',
    // urlAnimalConcerts: '/animalconcerts',
    component: Landing,
  },
};

export default privateRoute;
