import { Typography, Box, AppBar, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RoeInventory from './RoeInventory';
import HowToBuy from './HowToBuy';
import { styled } from '@mui/system';
import { DateTime } from 'luxon';
import NumberFormat from 'react-number-format';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  [`& .${tabsClasses.root}, .MuiTabs-scroller`]: {
    padding: '24px !important',
    margin: '-24px !important',
  },
  [`& .${tabsClasses.flexContainer}`]: {
    flexWrap: 'wrap',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#E2E4E9',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '8px',
  border: '1px #383A3E solid',
  borderRadius: '28px',
  position: 'relative',
  background: 'transparent',
  marginBottom: '6px',
  '&.Mui-selected': {
    color: '#EECE7C',
    border: '1px solid #EECE7C',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      borderRadius: 'inherit',
      background: 'linear-gradient(to right bottom, #260B3C, #a053df)',
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const RoeTabs = ({ receivedTimeFromServer }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatTime = (timeISO) => {
    return DateTime.fromISO(timeISO).toUTC().toFormat('dd MMMM yyyy HH:mm');
  };

  const dataList = [
    { num: 'TGE', date: '02-05-2022 12:01 PM UTC', percent: '10%', type: 'Airdropped' },
    { num: '1', date: '02-06-2022 12:01 PM UTC', percent: '15%', type: 'Claim on Spores Launchpad' },
    { num: '2', date: '02-07-2022 12:01 PM UTC', percent: '15%', type: 'Claim on Spores Launchpad' },
    { num: '3', date: '02-08-2022 12:01 PM UTC', percent: '15%', type: 'Claim on Spores Launchpad' },
    { num: '4', date: '02-09-2022 12:01 PM UTC', percent: '15%', type: 'Claim on Spores Launchpad' },
    { num: '5', date: '02-10-2022 12:01 PM UTC', percent: '15%', type: 'Claim on Spores Launchpad' },
    { num: '6', date: '02-11-2022 12:01 PM UTC', percent: '15%', type: 'Claim on Spores Launchpad' },
  ];

  return (
    <div className='w-full'>
      <StyledAppBar position='static' className='p-0'>
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary' textColor='inherit'>
          <StyledTab label='Distribution' {...a11yProps(0)} />
          <StyledTab label='Token Metrics' {...a11yProps(1)} />
          <StyledTab label='Sale details' {...a11yProps(2)} />
          <StyledTab label='Project information' {...a11yProps(3)} />
          <StyledTab label='Restrictions' {...a11yProps(4)} />
          <StyledTab label='How to buy' {...a11yProps(5)} />
        </Tabs>
      </StyledAppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Release Date (UTC)</TableCell>
                <TableCell>Percentage</TableCell>
                <TableCell>Claim type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell rowSpan={1}>{item.num}</TableCell>
                      <TableCell rowSpan={1}>{item.date}</TableCell>
                      <TableCell rowSpan={1}>{item.percent}</TableCell>
                      <TableCell rowSpan={1}>{item.type}</TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table> */}
          <div className='font-semibold'>Daily linear unlock from 20 May 2022 08:54 AM to 20 November 2022 08:54 AM (UTC)</div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className='w-full' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Token name',
                info: 'Realms of Ethernity (RETH)',
              },
              {
                title: 'Network',
                info: 'Polygon',
              },
              {
                title: 'Total supply',
                info: '1,378,000,000 RETH',
              },
              {
                title: 'Initial Circulating Supply',
                info: '17,500,000 RETH',
              },
              {
                title: 'Initial Market Cap',
                info: '$525,000',
              },
              {
                title: 'Token listing',
                info: 'Polygon',
              },
              {
                title: 'Vesting details',
                info: '10% unlock at TGE, 15% monthly unlock for 6 months',
              },
            ].map((item, index) => (
              <div
                key={index}
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px #41464E solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#fff' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel className='bg-inherit' value={value} index={2} dir={theme.direction}>
          <div className='w-full' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Total Raise',
                info: '$50,000',
              },
              {
                title: 'Token price',
                info: '$0.03',
              },
              {
                title: 'KYC',
                info: 'Yes',
              },
              {
                title: 'Start/End:',
                info: '13 April 2022 13:00 - 15 April 2022 14:00 (UTC)',
              },
              {
                title: 'VIP Allocation Round',
                info: '13 April 2022 13:00 (UTC)',
              },
              {
                title: 'Double Quota Round',
                info: '14 April 2022 13:00 (UTC)',
              },
              {
                title: 'FCFS Round (VIP only)',
                info: '14 April 2022 14:00 (UTC)',
              },
              {
                title: 'Network',
                info: 'IDO on BNB Chain; Distribution on Polygon',
              },
              {
                title: 'Registration time',
                info: 'Until 13 April 2022 13:00 (UTC)',
              },
            ].map((item, index) => (
              <div
                key={index}
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px #41464E solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#fff' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <RoeInventory />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <div style={{ color: '#A2A5A9', fontSize: '18px' }}>
            Restricted Countries & Territories:
            <br />
            <br /> Afghanistan , Angola , Azerbaijan , Bahamas , Bosnia and Herzegovina , Botswana , Burundi , Cambodia,
            Cameroon, Canada , Chad , China , Congo (Congo-Brazzaville) , Cuba , Democratic, Republic of the Congo ,
            Eritrea , Ethiopia , Ghana , Guinea , Guinea-Bissau , Haiti , Iran , Iraq , Laos , Libya , Madagascar ,
            Mozambique , Nicaragua , North Korea , Pakistan , Serbia , Seychelles , Somalia , South Sudan , Sri Lanka ,
            Sudan , Syria , Tajikistan , Trinidad and Tobago , Tunisia , Turkmenistan , Uganda , United States of
            America , Uzbekistan , Vanuatu , Venezuela , Yemen , Zimbabwe.
          </div>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <HowToBuy />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
export default RoeTabs;
