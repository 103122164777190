import { Grid, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const SupernovaInventory = () => {
  return (
    <div style={{ color: '#A2A5A9', fontSize: '18px' }}>
      <div>
        Supernova is a sci-fi sandbox survival MMORPG in an open world featuring indirect controls and an open economy
        with play to earn mechanics. Supernova is a sci-fi sandbox survival MMORPG in an open world featuring indirect
        controls and an open economy with play to earn mechanics! Engage in many in-game professions, including trading,
        politics, war, piracy, and exploration! We invite you to explore a huge open world with endless possibilities on
        any platform (browser, mobile, desktop).
      </div>
      <div className='flex my-6'>
        <MuiLink
          href='https://sunday.games/supernova/'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Official Website'
        >
          <img src={require('assets/icons/media/website.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://discord.gg/a8HruHc'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Discord'
        >
          <img src={require('assets/icons/media/discord.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.facebook.com/SupernovaVerse'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Facebook'
        >
          <img src={require('assets/icons/media/facebook.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://t.me/+jNNGMc-lkzJlMjZi'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Telegram'
        >
          <img src={require('assets/icons/media/telegram.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://twitter.com/SupernovaVerse'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Twitter'
        >
          <img src={require('assets/icons/media/twitter.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.youtube.com/playlist?list=PLjE7WiirRW9ow4kBzhDbL7dbcdpJLA0C5'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100 flex'
          title='Youtube'
        >
          <img src={require('assets/icons/media/youtube.svg').default} />
        </MuiLink>
      </div>
      <div className='font-bold text-white mb-4' style={{ fontSize: '36px' }}>
        KEY FEATURES
      </div>
      <div className='font-bold mb-4' style={{ fontSize: '18px', color: '#EECE7C' }}>
        Open World
      </div>
      <div className='mb-4'>
        Character automated fights, work, starship flying, players give out general orders. This removes routine from
        the players and draws attention to more important management elements within the game.
      </div>
      <div className='font-bold mb-4' style={{ fontSize: '18px', color: '#EECE7C' }}>
        Character Independency
      </div>
      <div className='mb-4'>
        Character automated fights, work, starship flying, players give out general orders. This removes routine from
        the players and draws attention to more important management elements within the game.
      </div>
      <div className='font-bold mb-4' style={{ fontSize: '18px', color: '#EECE7C' }}>
        Open Economy
      </div>
      <div className='mb-4'>
        Players will be able to purchase in-game tokens for fiat currency, but also exchange them back. Trades,
        collaborations, armed clashes, and other socio-economic relations between players will not only bring immersive
        gameplay but also an opportunity to earn money.
      </div>
      <div className='font-bold mb-4' style={{ fontSize: '18px', color: '#EECE7C' }}>
        UGC Oriented
      </div>
      <div className='mb-4'>
        The Supernova-verse is open to creators. Players can craft their own unique ship designs, equipment, space and
        planetary stations, narrative adventures, and other content like importing their NFT’s while being rewarded for
        that. Thanks to this approach, the game world will receive regular and multicultural updates.
      </div>
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Roadmap
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/supernova/info1.png').default} />
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Corporations
      </div>
      <div className='mb-4'>
        In the Supernova-verse, the division of people by place of birth, race, or faith has lost its meaning. States
        were replaced by Corporations, it is they who divide the spheres of influence of known star systems and discover
        new ones.
      </div>
      <div className='mb-4'>
        Corporations operate in similar principles to companies in the real world (or like DAO). Members of the
        corporation own its Government tokens. This allows to elect key managers and get a part of the corp revenue.
      </div>
      <div className='mb-4'>
        Corporations contend for control of star systems. They provide security from internal and external threats. It
        makes the area more attractive to other Supernova-verse residents and allows collecting more taxes.
      </div>
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Economics
      </div>
      <div className='mb-4'>
        The following real-world analogy illustrates how the Supernova-verse economy works. Imagine that there is only
        one country in the world that occupies the territory of only one continent (inhabited star systems at the start
        of the game). The government of this country (Life Corporation, the game developer) maintains order on its
        territory, monitors the turnover of the national currency (LFC), and performs other service functions, in return
        collecting taxes from the trading operations of its residents (players). Some residents act as entrepreneurs,
        they develop mining, industry, science, and trade, some work for hire, some operate outside the law, and are
        engaged in piracy.
      </div>
      <div className='mb-4'>
        Over time, residents begin to develop new territories and establish their countries (new corporations). The
        governments of the new countries issue their own currency and establish their own laws, yet the international
        currency (UNIT), which is operated by an international bank (Interstellar Bank, the game developer), is popular
        due to its stability.
      </div>
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Blueprints NFT
      </div>
      <div className='mb-4'>
        Scientists will be able to invent new blueprints. They serve as intellectual property in the Supernova-verse.
        The one who owns the blueprint, components, and production facilities can produce new goods. In addition, the
        blueprint can be licensed to other players, allowing to collect royalties from each copy produced.
      </div>
      <div className='mb-4'>
        The blueprints are NFT made according to ERC-1155 standard. Just like LFC, they can be in two states – internal
        and external. Each blueprint can only have ten copies.
      </div>
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Team
      </div>
      <div className='mb-4'>
        By December 2021, the team at Sunday Games has grown to 16 people with a very diverse technology background. The
        average experience in game development for each member is over ten years.{' '}
      </div>
      <div className='mb-4'>
        With an ambitious team that has experience in mainstream game development and has been involved with blockchain
        gaming since the first projects appeared in 2017, Supernova is on track to become one of the most anticipated
        games for blockchain enthusiasts and newcomers from traditional gaming. By combining the team’s experience from
        both worlds, Sunday Games aims to make Supernova accessible to everyone.
      </div>
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Investors
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/supernova/info2.png').default} />
      <div className='font-bold text-white py-4' style={{ fontSize: '26px' }}>
        Partners
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/supernova/info3.png').default} />
    </div>
  );
};

export default SupernovaInventory;
