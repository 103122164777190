import { Grid } from '@mui/material';
import React from 'react';
import { RoeClaimCard } from 'views/Roe/components';
import { RoeTabs } from './components';
import { useQuery } from 'react-query';
import { SupportButton } from 'components';

const RoeLanding = () => {

  return (
    <div className='text-white' style={{ backgroundColor: '#191B1E' }}>
      <SupportButton />
      <div
        className='p-4 lg:px-20 lg:pt-8 flex flex-col items-center justify-center'
        style={{
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid
          container
          spacing={4}
          className='flex justify-center items-start'
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={4} xl={3.5} className='xl:sticky top-1'>
            <RoeClaimCard/>
          </Grid>
          <Grid item md={12} lg={8} xl={8.5}>
            <div
              style={{
                borderRadius: '8px',
              }}
              className='justify-between max-w-screen-xl'
            >
              <img className='mb-8 w-full' src={require('assets/projects/roe/inventory.png').default} />
              <RoeTabs/>
            </div>
          </Grid>
        </Grid>
        <div
          columns={12}
          className='flex justify-center items-start mt-10'
          style={{
            maxWidth: '1500px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default RoeLanding;
