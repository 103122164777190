import { web3 } from 'contracts/contract';

export const formatNumber = (number) =>
  Number(number).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 6,
  });
export const decimalFormat = (number) => web3.utils.fromWei(number.toString(16));
export const decimalFormatSuperNova = (number) => web3.utils.fromWei(number.toString(16), 'gwei');
export const shorten = (address, head = 6, tail = 4) => {
  if (typeof address !== 'string') return address;
  return address.slice(0, head) + '...' + address.slice(address.length - tail);
};
