import { Button, LinearProgress, linearProgressClasses, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link as MuiLink } from '@mui/material';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles(
  {
    stillCalculate: { visibility: 'hidden' },
    showAll: { color: '#B6B6BE' },
    showLess: {
      color: '#B6B6BE',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
    },
    countdownLabel: {
      margin: 'auto',
      fontSize: '12px',
      lineHeight: '16px',
    },
    countdownValue: {
      width: '50px',
      height: '50px',
      background: 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
      borderRadius: '10px',
    },
    countdownUnit: {
      margin: 'auto',
      fontSize: '12px',
      lineHeight: '44px',
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  { name: 'CampaignDetailsLeftCard' },
);

export const TotalRaiseProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: '#222325',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    background: 'linear-gradient(270deg, rgba(255, 239, 94, 1) 0%, rgba(247, 147, 111, 1) 100%)',
  },
}));

export const ButtonCurrency = styled(Button)(({ theme }) => ({
  background: 'transparent',
  '&:hover': {
    background: '#3A3A3C',
  },
  height: '36px',
  width: '75px',
  boxSizing: 'border-box',
  borderRadius: '8px',
  color: '#767679',
  fontSize: '14px',
  border: '1px solid #3C3C3E',
  '&.active': {
    color: '#EECE7C',
    border: '1px solid #EECE7C',
    // boxShadow: '0px 3px 20px #7000FF',
    background: '#262627',
    '&:hover': {
      background: '#404042',
    },
  },
}));

function OokeengaIDOLeftCard() {
  const [isShowAll, setIsShowAll] = useState(false);
  const classes = useStyles();
  return (
    <Paper className='px-8 py-6 mb-8 w-full' style={{ backgroundColor: '#262627' }}>
      <div className='flex gap-6 content-center'>
        <div>
          <img
            className='rounded-full'
            src={require('assets/projects/ookeenga/big-logo.png').default}
            width={120}
            height={120}
            alt="Campaign's logo"
          />
        </div>
        <div className='flex flex-col' style={{ overflow: 'clip' }}>
          <h1 className='text-white font-bold text-2xl sm:text-5xl' style={{ fontSize: '48px' }}>
          Ookeenga IDO
          </h1>
          <div className='flex my-6 items-center'>
            <MuiLink
              href='https://ookeenga.io/'
              className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
              title='Official Website'
            >
              <img src={require('assets/icons/media/website.svg').default} />
            </MuiLink>

            <MuiLink
              href='https://t.me/ookeenga_global'
              className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
              title='Telegram'
            >
              <img src={require('assets/icons/media/telegram.svg').default} />
            </MuiLink>

            <MuiLink
              href='https://twitter.com/ookeenga'
              className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
              title='Twitter'
            >
              <img src={require('assets/icons/media/twitter.svg').default} />
            </MuiLink>

            <MuiLink
              href='https://discord.com/invite/5wqczdjMSQ'
              className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
              title='Discord'
            >
              <img src={require('assets/icons/media/discord.svg').default} />
            </MuiLink>

            <MuiLink
              href='https://www.youtube.com/channel/UCqQpViSkkAq_aFP3hj3GL8w'
              className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
              title='Official Website'
            >
              <img src={require('assets/icons/media/youtube.svg').default} />
            </MuiLink>

            <MuiLink
              href='https://www.facebook.com/ookeenga.official'
              className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
              title='Official Website'
            >
              <img src={require('assets/icons/media/facebook.svg').default} />
            </MuiLink>
          </div>
        </div>
      </div>
      <div className='mt-2 hidden lg:flex' style={{color: '#B6B6BE'}}>
        Ookeenga Web3 Game is the first product of a long-term visionary project of the Ookeenga franchise, including
        Web2 games, mobile games, and merchandise that revolve around the world of Ookeenga.
        <br />
        Inspired by the hit game Clash Royale which has easy but addictive gameplay, Ookeenga is set in a world where
        insects have evolved and built a massive civilization. The game is a combination of 3 genres that are common in
        the gaming community: Real-time Strategy, Collectible Card, and Tower Defense. This makes the game easy to pick
        up but takes time to master.
      </div>
      <div className='lg:hidden block'>
        <div className={clsx('mt-2', isShowAll ? classes.showAll : classes.showLess)}>
          Ookeenga Web3 Game is the first product of a long-term visionary project of the Ookeenga franchise, including
          Web2 games, mobile games, and merchandise that revolve around the world of Ookeenga.
          <br />
          Inspired by the hit game Clash Royale which has easy but addictive gameplay, Ookeenga is set in a world where
          insects have evolved and built a massive civilization. The game is a combination of 3 genres that are common
          in the gaming community: Real-time Strategy, Collectible Card, and Tower Defense. This makes the game easy to
          pick up but takes time to master.
        </div>
        {isShowAll === false && (
          <div className='flex justify-start'>
            <button onClick={() => setIsShowAll(true)} className='text-white cursor-pointer underline'>
              Read more
            </button>
          </div>
        )}
      </div>
      <div className='mt-2 font-bold' style={{color: '#F8D67B'}}>
        Ookeenga IDO is under the{' '}
        <a
          href='https://spores.medium.com/spores-network-to-introduce-safeguard-program-5b7f529f106b'
          target='_blank'
          className='underline underline-offset-1'
        >
          Spores Safeguard Program
        </a>
        .
      </div>
        <Paper style={{ backgroundColor: '#3C3C3E' }} className='p-9 mt-4 flex justify-center items-center'>
          <div className='text-white font-bold'>SOLD OUT</div>
        </Paper>
    </Paper>
  );
}

export default OokeengaIDOLeftCard;
