import { Typography, Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SupernovaInventory from './SupernovaInventory';
import HowToBuy from './HowToBuy';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  [`& .${tabsClasses.root}, .MuiTabs-scroller`]: {
    padding: '24px !important',
    margin: '-24px !important',
  },
  [`& .${tabsClasses.flexContainer}`]: {
    flexWrap: 'wrap',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#E2E4E9',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '8px',
  border: '1px #383A3E solid',
  borderRadius: '28px',
  position: 'relative',
  background: 'transparent',
  marginBottom: '6px',
  '&.Mui-selected': {
    color: '#EECE7C',
    border: '1px solid #EECE7C',
    ['&:before']: {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      borderRadius: 'inherit',
      background: 'linear-gradient(to right bottom, #260B3C, #a053df)',
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const SupernovaTabs = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className='w-full'>
      <StyledAppBar position='static' className='p-0'>
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary' textColor='inherit'>
        <StyledTab label='Distribution' {...a11yProps(0)} />
          <StyledTab label='Sale details' {...a11yProps(1)} />
          <StyledTab label='Token Metrics' {...a11yProps(2)} />
          <StyledTab label='Project information' {...a11yProps(3)} />
          <StyledTab label='Restrictions' {...a11yProps(4)} />
          <StyledTab label='How to buy' {...a11yProps(5)} />
        </Tabs>
      </StyledAppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel className='bg-inherit' value={value} index={0} dir={theme.direction}>
        <div style={{ color: '#FAFAFA', fontSize: '24px' }} className='py-8 font-bold'>
        Vesting Schedule
          </div>
          <ul class='list-disc list-inside' style={{ color: '#B6B6BE', fontSize: '18px' }}>
              <li>
              25 March 2022 13:00 UTC: Release 25%
              </li>
              <li>03-month daily linear vesting: Starts from 26 March 2022 13:00 UTC</li>
            </ul>
        </TabPanel>
        <TabPanel className='bg-inherit' value={value} index={1} dir={theme.direction}>
          <div className='w-full' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Total Raise',
                info: '$30,000',
              },
              {
                title: 'Token price',
                info: '$0.30',
              },
              {
                title: 'KYC',
                info: 'Yes',
              },
              {
                title: 'Start/End:',
                info: ' 23 March 2022 13:00 - 24 March 2022 14:00 (UTC)',
              },
              {
                title: 'VIP Allocation Round',
                info: '23 March 2022 13:00 (UTC)',
              },
              {
                title: 'Double Quota Round',
                info: '24 March 2022 01:00 (UTC)',
              },
              {
                title: 'FCFS Round (VIP access only)',
                info: '24 March 2022 02:00 (UTC)',
              },
              {
                title: 'Network',
                info: 'IDO and Distribution on BNB Chain',
              },
              {
                title: 'Registration time',
                info: '17 March 2022 13:00  - 22 March 2022 13:00 (UTC)',
              },
            ].map((item, index) => (
              <div
                key={index}
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px #41464E solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#ffffff' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className='w-full' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Token name',
                info: 'Life Coin (LFC)',
              },
              {
                title: 'Network',
                info: 'BNB Chain',
              },
              {
                title: 'Total supply',
                info: '100,000,000 LFC',
              },
              {
                title: 'Initial Circulating Supply',
                info: '1,710,000 LFC',
              },
              {
                title: 'Initial Market Cap',
                info: '$513,000',
              },
              {
                title: 'Token listing',
                info: 'TBA',
              },
              {
                title: 'Vesting details',
                info: '25% on TGE (03/25/2022, 13:00 UTC) \n then linear daily over 3 months',
              },
            ].map((item, index) => (
              <div
                key={index}
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px #41464E solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#ffffff' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <SupernovaInventory />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <div style={{ color: '#A2A5A9', fontSize: '18px' }} className='py-8'>
            Restricted Countries & Territories:
          </div>
          <div style={{ color: '#A2A5A9', fontSize: '18px' }}>
            Afghanistan , Angola , Azerbaijan , Bahamas , Bosnia and Herzegovina , Botswana , Burundi , Cambodia,
            Cameroon, Canada , Chad , China , Congo (Congo-Brazzaville) , Cuba , Democratic, Republic of the Congo ,
            Eritrea , Ethiopia , Ghana , Guinea , Guinea-Bissau , Haiti , Iran , Iraq , Laos , Libya , Madagascar ,
            Mozambique , Nicaragua , North Korea , Pakistan , Serbia , Seychelles , Somalia , South Sudan , Sri Lanka ,
            Sudan , Syria , Tajikistan , Trinidad and Tobago , Tunisia , Turkmenistan , Uganda , United States
            of America , Uzbekistan , Vanuatu , Venezuela , Yemen , Zimbabwe.
          </div>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <HowToBuy />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
export default SupernovaTabs;
