import { Grid, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const Instruction = () => {
  return (
    <div className='sm:text-sm rounded-lg text-white font-bold p-12' style={{ backgroundColor: '#262627' }}>
      <Grid container spacing={4} className='flex justify-center items-start' style={{ maxWidth: '1600px' }}>
        <Grid item xs={2.4} md={2.4} lg={2.4} xl={2.4} className=''>
          <div>
            <img src={require('assets/projects/roe/instruction1.png').default} className='w-full mb-4' />
            <span>1. Change network to Ethereum</span>
          </div>
        </Grid>
        <Grid item xs={2.4} md={2.4} lg={2.4} xl={2.4} className=''>
          <div>
            <img src={require('assets/projects/roe/instruction2.png').default} className='w-full mb-4' />
            <span>2. Click the icon in the top right corner, then choose “Setting”</span>
          </div>
        </Grid>
        <Grid item xs={2.4} md={2.4} lg={2.4} xl={2.4} className=''>
          <div>
            <img src={require('assets/projects/roe/instruction3.png').default} className='w-full mb-4' />
            <span>3. Choose “Networks”</span>
          </div>
        </Grid>
        <Grid item xs={2.4} md={2.4} lg={2.4} xl={2.4} className=''>
          <div>
            <img src={require('assets/projects/roe/instruction4.png').default} className='w-full mb-4' />
            <span>4. Choose Polygon network in the list</span>
          </div>
        </Grid>
        <Grid item xs={2.4} md={2.4} lg={2.4} xl={2.4} className=''>
          <div>
            <img src={require('assets/projects/roe/instruction5.png').default} className='w-full mb-4' />
            <span>5. Click the “Delete” button at the bottom and confirm in the popup</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Instruction;
