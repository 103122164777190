import { createSlice } from '@reduxjs/toolkit';
import { storageService } from 'services/storage';

const storageKey = 'profile'

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoggedIn: false,
    ...(
      JSON.parse(
        localStorage.getItem(storageKey) || '{}'
      )
    )
  },
  reducers: {
    signIn: (state, { payload }) => {
      state = { ...state, ...payload, isLoggedIn: true };
      localStorage.setItem(storageKey, JSON.stringify(state));
      return state;
    },
    signOut: (state) => {
      state = { isLoggedIn: false };
      localStorage.removeItem(storageKey);
      storageService.clearOrder();
      return state;
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export default profileSlice.reducer;
