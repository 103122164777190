import { Grid, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import { SupernovaClaimCard } from 'views/Supernova/components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { SupernovaTabs } from './components';
import { SupportButton } from 'components';

const SupernovaLanding = () => {
  const [item, setItem] = React.useState(0);
  const handleClickCard = (name) => {
    setItem(name);
  };
  const BundleTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#48484A',
        width: '800px',
      },
    }),
  );
  return (
    <div className='text-white' style={{ backgroundColor: '#191B1E' }}>
      <SupportButton />
      <div
        className='p-4 lg:px-20 lg:pt-8 flex flex-col items-center justify-center'
        style={{
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid
          container
          spacing={4}
          className='flex justify-center items-start'
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={4} xl={3.5} className='xl:sticky top-1'>
            <SupernovaClaimCard />
          </Grid>
          <Grid item md={12} lg={8} xl={8.5}>
            <div
              style={{
                borderRadius: '8px',
              }}
              className='justify-between max-w-screen-xl'
            >
              <img className='mb-8 w-full' src={require('assets/projects/supernova/inventory.png').default} />
              <SupernovaTabs />
            </div>
          </Grid>
        </Grid>
        <div
          columns={12}
          className='flex justify-center items-start mt-10'
          style={{
            maxWidth: '1500px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default SupernovaLanding;
