import { Typography, Box, AppBar, Link as MuiLink } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import { DateTime } from 'luxon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  [`& .${tabsClasses.root}, .MuiTabs-scroller`]: {
    padding: '24px !important',
    margin: '-24px !important',
  },
  [`& .${tabsClasses.flexContainer}`]: {
    flexWrap: 'wrap',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#E2E4E9',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '8px',
  border: '1px #383A3E solid',
  borderRadius: '28px',
  position: 'relative',
  background: 'transparent',
  marginBottom: '6px',
  '&.Mui-selected': {
    color: '#EECE7C',
    border: '1px solid #EECE7C',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      borderRadius: 'inherit',
      background: 'linear-gradient(to right bottom, #260B3C, #a053df)',
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const OokeengaTabs = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatTime = (timeISO) => {
    return DateTime.fromISO(timeISO).toUTC().toFormat('dd MMMM yyyy HH:mm');
  };

  const dataList = [
    { num: 'TGE', date: 'Sep 14th 2022, 10:00 AM UTC', percent: '15%', type: 'Airdropped' },
    { num: '1', date: 'Oct 14th 2022, 10:00 AM UTC', percent: '14.17%', type: 'Claim on Spores Launchpad' },
    { num: '2', date: 'Nov 14th 2022, 10:00 AM UTC', percent: '14.17%', type: 'Claim on Spores Launchpad' },
    { num: '3', date: 'Dec 14th 2022, 10:00 AM UTC', percent: '14.17%', type: 'Claim on Spores Launchpad' },
    { num: '4', date: 'Jan 14th 2023, 10:00 AM UTC', percent: '14.17%', type: 'Claim on Spores Launchpad' },
    { num: '5', date: 'Feb 14th 2023, 10:00 AM UTC', percent: '14.17%', type: 'Claim on Spores Launchpad' },
    { num: '6', date: 'Mar 14th 2022, 10:00 AM UTC', percent: '14.15%', type: 'Claim on Spores Launchpad' },
  ];

  return (
    <div className='w-full'>
      <StyledAppBar position='static' className='p-0'>
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary' textColor='inherit'>
          <StyledTab label='Distribution' {...a11yProps(0)} />
          <StyledTab label='Token Metrics' {...a11yProps(1)} />
        </Tabs>
      </StyledAppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div className='w-full pr-1'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Release Date (UTC)</TableCell>
                  <TableCell>Percentage</TableCell>
                  <TableCell>Claim type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell rowSpan={1}>{item.num}</TableCell>
                    <TableCell rowSpan={1}>{item.date}</TableCell>
                    <TableCell rowSpan={1}>{item.percent}</TableCell>
                    <TableCell rowSpan={1}>{item.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TabPanel>
        <TabPanel className='bg-inherit' value={value} index={1} dir={theme.direction}>
          <div className='flex flex-col lg:flex-row lg:gap-6'>
            <div className='w-full lg:w-1/2' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
              {[
                {
                  title: 'Total Raise',
                  info: '$50,000',
                },
                {
                  title: 'Token price',
                  info: '$0.05',
                },
                {
                  title: 'Start time:',
                  info: 'Sep 10 2022, 10:00 AM UTC',
                },
                {
                  title: 'End time:',
                  info: 'Sep 12 2022, 11:00 AM UTC',
                },
                {
                  title: 'VIP Allocation Round',
                  info: 'Sep 10 2022, 10:00 AM UTC',
                },
                {
                  title: 'VIP Triple Quota Round',
                  info: 'Sep 11 2022, 10:00 AM UTC',
                },
                {
                  title: 'FCFS Round for all',
                  info: 'Sep 12 2022, 11:00 AM UTC',
                },
                {
                  title: 'Registration time',
                  info: 'Until Sep 09 2022, 10:00 AM UTC',
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className='flex justify-between items-end md:text-left px-6 py-4'
                  style={{ borderBottom: '1px #41464E solid' }}
                >
                  {item.url ? (
                    <div className='font-bold' style={{ fontSize: '16px', color: '#cfb657' }}>
                      {item.title}
                    </div>
                  ) : (
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      {item.title}
                    </div>
                  )}

                  {item.url ? (
                    <MuiLink
                      href={item.url}
                      className='text-right hover:underline cursor-pointer font-bold'
                      style={{ fontSize: '16px', color: '#cfb657' }}
                    >
                      {item.info}
                    </MuiLink>
                  ) : (
                    <div className='font-bold text-right' style={{ fontSize: '16px', color: '#fff' }}>
                      {item.info}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='w-full lg:w-1/2 ' style={{ border: '1px #41464E solid', borderWidth: '1px 0px 0px 0px' }}>
              {[
                {
                  title: 'Token name',
                  info: 'OKG',
                },
                {
                  title: 'Network',
                  info: 'BNB Chain',
                },
                {
                  title: 'Total supply',
                  info: '500,000,000 OKG',
                },
                {
                  title: 'Initial Circulating Supply',
                  info: '5,400,000 OKG',
                },
                {
                  title: 'Initial Market Cap',
                  info: '$270,000',
                },
                {
                  title: 'Token listing',
                  info: 'TBA',
                },
                {
                  title: 'Vesting details',
                  info: '15% on TGE, monthly vesting over 6 months',
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className='flex justify-between items-end md:text-left px-6 py-4'
                  style={{ border: '1px #41464E solid', borderWidth: '0px 1px 1px 1px' }}
                >
                  {item.url ? (
                    <div className='font-bold' style={{ fontSize: '14px', color: '#cfb657' }}>
                      {item.title}
                    </div>
                  ) : (
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      {item.title}
                    </div>
                  )}

                  {item.url ? (
                    <MuiLink
                      href={item.url}
                      className='text-right hover:underline cursor-pointer font-bold'
                      style={{ fontSize: '16px', color: '#cfb657' }}
                    >
                      {item.info}
                    </MuiLink>
                  ) : (
                    <div className='font-bold text-right' style={{ fontSize: '16px', color: '#fff' }}>
                      {item.info}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
export default OokeengaTabs;
