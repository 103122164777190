import {
  Button,
  Dialog,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Tooltip,
  tooltipClasses,
  BadgeMark,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Star } from 'assets/projects/animalia/star.svg';
import { vesting as _vestingContract } from 'contracts/abis';
import { erc20Contract, web3, vestingContract } from 'contracts/contract';
import { usePopup } from 'hooks';
import { DateTime } from 'luxon';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { connectWalletOnly } from 'reducers/profileAction';
import { marketService } from 'services/market';
import { storageService } from 'services/storage';
import { CampTimer } from 'utils/campaign';
import { formatNumber, decimalFormat } from 'utils/common';
import { PopupCart, PopupDone } from 'views/Checkout';
import BN from 'bignumber.js';

export const TotalRaiseProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: 'linear-gradient(90deg, #F1F1F5 0%, #E4ECF7 100%)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    background: 'linear-gradient(90deg, rgba(73,108,251,1) 0%, rgba(214,101,255,1) 100%)',
  },
}));

export const ButtonCurrency = styled(Button)(({ theme }) => ({
  background: '#3A3A3C',
  '&:hover': {
    background: '#3A3A3C',
  },
  height: '44px',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '4px',
  color: '#9994A3',
  fontSize: '14px',
  '&.active': {
    color: '#ffffff',
    boxShadow: '0px 3px 20px #7000FF',
    background: '#7659AE',
    '&:hover': {
      background: '#7659AE',
    },
  },
}));
const VestingContract = vestingContract('0xCaeC0F072C6D54511F10A1CFD4b65DCBDb465736');

function WizardiaClaimCard() {
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const getVestingData = async (address) => {
    const claimed = await VestingContract.methods.claimed(address).call();
    const [, vestingAmounts] = await VestingContract.methods.getPolicy(address).call();
    const tokenAddr = await VestingContract.methods.token().call();

    const decimal = await erc20Contract(tokenAddr).methods.decimals().call();

    const totalPurchased = vestingAmounts.reduce((pre, curr) => {
      return pre.plus(new BN(curr));
    }, new BN(0));

    let available = await VestingContract.methods.getAvailAmt(address).call();

    return {
      claimed: decimalFormat(new BN(claimed)),
      available: decimalFormat(new BN(available)),
      locked: decimalFormat(totalPurchased.minus(available).minus(claimed)),
      totalPurchased: decimalFormat(totalPurchased),
    };
  };

  const { data: vesting = {} } = useQuery(['fetchVestingInfo', address], () => getVestingData(address), {
    refetchOnMount: 'always',
    enabled: isLoggedIn,
    staleTime: 0,
    refetchInterval: 10 * 1000,
  });
  const Total = vesting.totalPurchased;
  const Available = vesting.available;
  const Claimed = vesting.claimed;
  const Locked = vesting.locked;
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();
  //claim
  const claim = React.useCallback(async () => {
    try {
      const tx = VestingContract.methods.claim();
      const transfer = await tx.send({ from: address });
    } catch (error) {
      console.log(error);
    } finally {
    }
  });

  return (
    <div>
      <div className='p-7 rounded-lg' style={{ backgroundColor: '#2C2C2E' }}>
        <div className='flex space-x-4 mb-4 justify-center xl:justify-start'>
          <img
            src={require('assets/projects/wizardia/ava.png').default}
            className='rounded-lg h-12 w-12 border-white border-4'
          />
          <div className=''>
            <div style={{ fontSize: '32px' }} className='text-white font-bold'>
              Wizardia IDO{' '}
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <div className='flex flex-col'>
            <div className='my-6 font-base' style={{ fontSize: '14px', color: '#A2A5A9' }}>
              Please see details below to claim your purchased WZRD.
            </div>
            <div className='px-5 py-4 rounded-lg mb-8 w-full' style={{ backgroundColor: '#3A3A3C' }}>
              <div className='flex justify-between items-end md:text-left mb-4'>
                <div className='font-base' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  Total
                </div>
                <div className='font-base' style={{ fontSize: '16px', color: '#ffffff' }}>
                  {Total ? formatNumber(Total) : '0'} WZRD
                </div>
              </div>
              <div className='flex justify-between items-end md:text-left mb-4'>
                <div className='font-base' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  Claimed
                </div>
                <div className='font-base' style={{ fontSize: '16px', color: '#ffffff' }}>
                  {Claimed ? formatNumber(Claimed) : '0'} WZRD
                </div>
              </div>
              <div className='flex justify-between items-end md:text-left mb-4'>
                <div className='font-base' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  Locked amount
                </div>
                <div className='font-base' style={{ fontSize: '16px', color: '#ffffff' }}>
                  {Total ? formatNumber(Locked) : '0'} WZRD
                </div>
              </div>
              <div
                className='flex mt-2 justify-between items-end md:text-left pt-4'
                style={{
                  lineHeight: '22px',
                  borderTop: '1px solid #6E757E',
                }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  Available to claim
                </div>
                <span
                  className='font-semibold bg-clip-text text-transparent'
                  style={{
                    fontSize: '16px',
                    color: '#ffffff',
                    background: '-webkit-linear-gradient(90deg, #F7936F 10%, #FFEF5E 70%, #FFEF5E 70%)',
                  }}
                >
                  {Total ? formatNumber(Available) : '0'} WZRD
                </span>
              </div>
            </div>
            <Button disabled={Available === '0' || !Available} className='h-14 text-base font-semibold' onClick={claim}>
              CLAIM
            </Button>
          </div>
        )}

        <div className='flex flex-col items-center'>
          {!isLoggedIn && (
            <Button className='w-full mt-4 h-14 text-base font-bold' onClick={() => connectWalletOnly()}>
              Connect wallet
            </Button>
          )}
        </div>
        <Dialog open={isOpenCart} maxWidth='md'>
          <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} />
        </Dialog>
        <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='md'>
          <PopupDone />
        </Dialog>
      </div>
    </div>
  );
}

export default WizardiaClaimCard;
