import { Link as MuiLink } from '@mui/material';
import React from 'react';

const RoeInventory = () => {
  return (
    <div style={{ color: '#A2A5A9', fontSize: '18px' }}>
      <div>
        Realms of Ethernity is the WoW (World of Warcraft) of WEB 3.0 gaming which provides AAA gameplay experience.In
        this next generation of MMORPG, players will explore dungeons, fight monsters and complete quests, collecting
        along the way in-game currency that represents fungible tokens that can be exchanged for existing or
        non-fungible tokens (items within the game).
      </div>

      <div className='flex my-6 items-center'>
        <MuiLink
          href='https://realmsofethernity.com/'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Official Website'
        >
          <img src={require('assets/icons/media/website.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://twitter.com/realmsofeth'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Twitter'
        >
          <img src={require('assets/icons/media/twitter.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://discord.com/invite/zTwtkYVZ'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Discord'
        >
          <img src={require('assets/icons/media/discord.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://t.me/realmsofethernityMMORPG'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Telegram'
        >
          <img src={require('assets/icons/media/telegram.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.youtube.com/channel/UC3JhLjVyIzftLpZ2YnM3kTw'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Youtube'
        >
          <img src={require('assets/icons/media/youtube.svg').default} />
        </MuiLink>
      </div>

      <img className='w-full' src={require('assets/projects/roe/info1.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info2.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info3.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info4.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info5.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info6.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info7.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info8.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info9.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info10.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info11.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info12.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info13.png').default} />
      <img className='w-full' src={require('assets/projects/roe/info14.png').default} />
    </div>
  );
};

export default RoeInventory;
